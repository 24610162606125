var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { wrap: "", "justify-start": "", "fill-height": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-form",
            { ref: "form", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    wrap: "",
                    "justify-start": "",
                    "px-2": "",
                    "px-sm-4": "",
                    "px-lg-8": "",
                  },
                },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "mycontainer",
                      attrs: { xs12: "", "pr-4": "" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "justify-start": "", "py-8": "" },
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", lg4: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "justify-start": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs10: "", xl11: "" } },
                                    [
                                      _c("span", { staticClass: "title-2" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("form.enter_details")
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("p", { staticClass: "subtitle-2" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "messages.warning.all_mandatory"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", lg6: "", "offset-2": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "justify-start": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        lg6: "",
                                        "pa-lg-2": "",
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "input-label" }, [
                                        _vm._v(_vm._s(_vm.$t("form.ward"))),
                                      ]),
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.wards,
                                          "item-text": "name",
                                          "item-value": "id",
                                          outlined: "",
                                          dense: "",
                                          rules: [_vm.RULES.REQUIRED],
                                        },
                                        model: {
                                          value: _vm.ward_id,
                                          callback: function ($$v) {
                                            _vm.ward_id = $$v
                                          },
                                          expression: "ward_id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        lg6: "",
                                        "pa-lg-2": "",
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "input-label" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("form.department"))
                                        ),
                                      ]),
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.departments,
                                          multiple: "",
                                          "item-text": "name",
                                          "item-value": "id",
                                          outlined: "",
                                          dense: "",
                                          rules: [_vm.RULES.REQUIRED],
                                        },
                                        model: {
                                          value: _vm.department_id,
                                          callback: function ($$v) {
                                            _vm.department_id = $$v
                                          },
                                          expression: "department_id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        lg6: "",
                                        "pa-lg-2": "",
                                        "pt-4": "",
                                        "align-self-center": "",
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "input-label" }, [
                                        _vm._v(_vm._s(_vm.$t("form.case_no"))),
                                      ]),
                                      _c("v-text-field", {
                                        attrs: { dense: "", outlined: "" },
                                        model: {
                                          value: _vm.case_no,
                                          callback: function ($$v) {
                                            _vm.case_no = $$v
                                          },
                                          expression: "case_no",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        lg6: "",
                                        "pa-lg-2": "",
                                        "pt-4": "",
                                        "align-self-center": "",
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "input-label" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("form.date_range"))
                                        ),
                                      ]),
                                      _c("AppDatePicker", {
                                        attrs: {
                                          min: _vm.minDate,
                                          rules: [_vm.RULES.REQUIRED],
                                          range: "",
                                          "first-day-of-week": 1,
                                        },
                                        model: {
                                          value: _vm.date_range,
                                          callback: function ($$v) {
                                            _vm.date_range = $$v
                                          },
                                          expression: "date_range",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        lg6: "",
                                        "pa-lg-2": "",
                                        "pt-4": "",
                                        "align-self-center": "",
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "input-label" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("form.lettering"))
                                        ),
                                      ]),
                                      _c("v-textarea", {
                                        attrs: {
                                          rows: "4",
                                          dense: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.lettering,
                                          callback: function ($$v) {
                                            _vm.lettering = $$v
                                          },
                                          expression: "lettering",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        lg6: "",
                                        "pa-lg-2": "",
                                        "pt-lg-4": "",
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "input-label" }, [
                                        _vm._v(_vm._s(_vm.$t("form.gender"))),
                                      ]),
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          items: _vm.GENDERS,
                                          "item-value": "value",
                                          "item-text": "label",
                                          multiple: "",
                                        },
                                        model: {
                                          value: _vm.gender_id,
                                          callback: function ($$v) {
                                            _vm.gender_id = $$v
                                          },
                                          expression: "gender_id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        lg6: "",
                                        "pt-4": "",
                                        "pa-lg-2": "",
                                        "pt-lg-4": "",
                                        "align-self-center": "",
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "input-label" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("form.qualification"))
                                        ),
                                      ]),
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          multiple: "",
                                          items: _vm.qualifications,
                                          "item-text": "name",
                                          "item-value": "id",
                                          rules: [_vm.RULES.REQUIRED],
                                        },
                                        model: {
                                          value: _vm.qualification_id,
                                          callback: function ($$v) {
                                            _vm.qualification_id = $$v
                                          },
                                          expression: "qualification_id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        lg6: "",
                                        "pt-4": "",
                                        "pa-lg-2": "",
                                        "pt-lg-4": "",
                                        "align-self-center": "",
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "input-label" }, [
                                        _vm._v(_vm._s(_vm.$t("form.language"))),
                                      ]),
                                      _c("v-select", {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          multiple: "",
                                          items: _vm.languages,
                                          "item-text": "label",
                                          "item-value": "value",
                                        },
                                        model: {
                                          value: _vm.language_id,
                                          callback: function ($$v) {
                                            _vm.language_id = $$v
                                          },
                                          expression: "language_id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        lg6: "",
                                        "pt-4": "",
                                        "pa-lg-2": "",
                                        "pt-lg-4": "",
                                        "align-self-center": "",
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "input-label" }, [
                                        _vm._v(_vm._s(_vm.$t("form.shift"))),
                                      ]),
                                      _c("v-select", {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          multiple: "",
                                          items: _vm.shifts,
                                          "item-text": "label",
                                          "item-value": "id",
                                          rules: [_vm.RULES.REQUIRED],
                                        },
                                        model: {
                                          value: _vm.shift_id,
                                          callback: function ($$v) {
                                            _vm.shift_id = $$v
                                          },
                                          expression: "shift_id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.shift_id.length > 0
                                    ? _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            "pt-4": "",
                                            "pa-lg-2": "",
                                            "pt-lg-4": "",
                                            "align-self-center": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "input-label" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("form.shift_timing")
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._l(
                                            _vm.shifts,
                                            function (item, index) {
                                              return [
                                                _vm.shift_id.includes(item.id)
                                                  ? _c(
                                                      "v-layout",
                                                      {
                                                        key: "shift-" + index,
                                                        attrs: {
                                                          wrap: "",
                                                          "justify-start": "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs2: "",
                                                              "px-2": "",
                                                              "align-self-center":
                                                                "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.label
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs5: "",
                                                              "px-2": "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "input-label",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "form.start_time"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("v-text-field", {
                                                              directives: [
                                                                {
                                                                  name: "mask",
                                                                  rawName:
                                                                    "v-mask",
                                                                  value:
                                                                    "##:##",
                                                                  expression:
                                                                    "'##:##'",
                                                                },
                                                              ],
                                                              attrs: {
                                                                placeholder:
                                                                  "eg. 15:30",
                                                                dense: "",
                                                                outlined: "",
                                                                rules: [
                                                                  _vm.RULES
                                                                    .REQUIRED,
                                                                  _vm.RULES
                                                                    .WTIME,
                                                                ],
                                                              },
                                                              model: {
                                                                value:
                                                                  item.start_time,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "start_time",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.start_time",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs5: "",
                                                              "px-2": "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "input-label",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "form.end_time"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("v-text-field", {
                                                              directives: [
                                                                {
                                                                  name: "mask",
                                                                  rawName:
                                                                    "v-mask",
                                                                  value:
                                                                    "##:##",
                                                                  expression:
                                                                    "'##:##'",
                                                                },
                                                              ],
                                                              attrs: {
                                                                placeholder:
                                                                  "eg. 23:30",
                                                                dense: "",
                                                                outlined: "",
                                                                rules: [
                                                                  _vm.RULES
                                                                    .REQUIRED,
                                                                  _vm.RULES
                                                                    .WTIME,
                                                                ],
                                                              },
                                                              model: {
                                                                value:
                                                                  item.end_time,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "end_time",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.end_time",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "justify-start": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm9: "",
                                                lg9: "",
                                                xl6: "",
                                                "pt-4": "",
                                                "pa-sm-2": "",
                                                "pt-lg-4": "",
                                                "align-self-center": "",
                                              },
                                            },
                                            [
                                              _c("AppButton", {
                                                attrs: {
                                                  "custom-class": "pa-5",
                                                  block: "",
                                                },
                                                on: {
                                                  submit:
                                                    _vm.availableEmployees,
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "content",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "pages.services.show_available_employee_count"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ]),
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm3: "",
                                                lg3: "",
                                                xl6: "",
                                                "pt-4": "",
                                                "pa-sm-2": "",
                                                "pt-lg-4": "",
                                                "align-self-center": "",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticStyle: {
                                                  "background-color": "#ececec",
                                                },
                                                attrs: {
                                                  dense: "",
                                                  disabled: "",
                                                  readonly: "",
                                                  outlined: "",
                                                  "hide-details": "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.getServiceEmployeeCount,
                                                  callback: function ($$v) {
                                                    _vm.getServiceEmployeeCount =
                                                      $$v
                                                  },
                                                  expression:
                                                    "getServiceEmployeeCount",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-card",
            { attrs: { tile: "", height: "100%" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "justify-start": "", "fill-height": "" } },
                [
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        xs12: "",
                        "px-8": "",
                        "text-right": "",
                        "align-self-center": "",
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "px-4" },
                        [
                          _c("AppNavButton", {
                            attrs: {
                              route: { name: "services" },
                              color: "primary",
                              "custom-class": "pa-5",
                              outlined: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#3278ff" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("buttons.cancel")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { staticClass: "px-4" },
                        [
                          _c("AppButton", {
                            attrs: { "custom-class": "pa-5" },
                            on: { submit: _vm.manageForm },
                            scopedSlots: _vm._u([
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            `pages.services.${_vm.getButtonLabel}`
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SuccessConfirmation", {
        attrs: {
          title: _vm.successTitle,
          type: _vm.successType,
          item: _vm.slug,
          showDuplicate: "",
        },
        on: {
          submit: () => {
            _vm.resetForm()
            _vm.$router.go(-1)
          },
          cancel: () => {
            _vm.resetForm()
            _vm.toggleSuccess()
          },
          duplicate: function ($event) {
            return _vm.toggleSuccess()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("span", { staticClass: "subtitle-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("pages.services.available_employee_count")
                      ) +
                      " "
                  ),
                ]),
                _c("br"),
                _c("span", { staticClass: "title-2" }, [
                  _vm._v(" " + _vm._s(_vm.getServiceEmployeeCount) + " "),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.showSuccess,
          callback: function ($$v) {
            _vm.showSuccess = $$v
          },
          expression: "showSuccess",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }