/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start fill-height>
    <v-flex xs12>
      <v-form ref="form" lazy-validation>
        <v-layout wrap justify-start px-2 px-sm-4 px-lg-8>
          <v-flex xs12 pr-4 class="mycontainer">
            <v-layout wrap justify-start py-8>
              <v-flex xs12 lg4>
                <v-layout wrap justify-start>
                  <v-flex xs10 xl11>
                    <span class="title-2">
                      {{ $t("form.enter_details") }}
                    </span>
                    <p class="subtitle-2">
                      {{ $t("messages.warning.all_mandatory") }}
                    </p>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 lg6 offset-2>
                <v-layout wrap justify-start>
                  <!--                  <v-flex xs12 lg6 pa-lg-2>-->
                  <!--                    <p class="input-label">{{ $t("form.title") }}</p>-->
                  <!--                    <v-text-field-->
                  <!--                      dense-->
                  <!--                      outlined-->
                  <!--                      v-model="name"-->
                  <!--                      :rules="[RULES.REQUIRED]"-->
                  <!--                    />-->
                  <!--                  </v-flex>-->
                  <v-flex xs12 lg6 pa-lg-2>
                    <p class="input-label">{{ $t("form.ward") }}</p>
                    <v-select
                      :items="wards"
                      v-model="ward_id"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      :rules="[RULES.REQUIRED]"
                    />
                  </v-flex>
                  <v-flex xs12 lg6 pa-lg-2>
                    <p class="input-label">{{ $t("form.department") }}</p>
                    <v-select
                      :items="departments"
                      v-model="department_id"
                      multiple
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      :rules="[RULES.REQUIRED]"
                    />
                  </v-flex>
                  <v-flex xs12 lg6 pa-lg-2 pt-4 align-self-center>
                    <p class="input-label">{{ $t("form.case_no") }}</p>
                    <v-text-field v-model="case_no" dense outlined />
                  </v-flex>
                  <v-flex xs12 lg6 pa-lg-2 pt-4 align-self-center>
                    <p class="input-label">{{ $t("form.date_range") }}</p>
                    <AppDatePicker
                      v-model="date_range"
                      :min="minDate"
                      :rules="[RULES.REQUIRED]"
                      range
                      :first-day-of-week="1"
                    />
                  </v-flex>
                  <v-flex xs12 lg6 pa-lg-2 pt-4 align-self-center>
                    <p class="input-label">{{ $t("form.lettering") }}</p>
                    <v-textarea rows="4" v-model="lettering" dense outlined />
                  </v-flex>
                  <v-flex xs12 lg6 pa-lg-2 pt-lg-4>
                    <p class="input-label">{{ $t("form.gender") }}</p>
                    <v-select
                      outlined
                      dense
                      :items="GENDERS"
                      item-value="value"
                      item-text="label"
                      multiple
                      v-model="gender_id"
                    />
                  </v-flex>
                  <v-flex xs12 lg6 pt-4 pa-lg-2 pt-lg-4 align-self-center>
                    <p class="input-label">{{ $t("form.qualification") }}</p>
                    <v-select
                      outlined
                      dense
                      multiple
                      :items="qualifications"
                      item-text="name"
                      item-value="id"
                      v-model="qualification_id"
                      :rules="[RULES.REQUIRED]"
                    />
                  </v-flex>
                  <v-flex xs12 lg6 pt-4 pa-lg-2 pt-lg-4 align-self-center>
                    <p class="input-label">{{ $t("form.language") }}</p>
                    <v-select
                      dense
                      outlined
                      multiple
                      :items="languages"
                      v-model="language_id"
                      item-text="label"
                      item-value="value"
                    />
                  </v-flex>
                  <v-flex xs12 lg6 pt-4 pa-lg-2 pt-lg-4 align-self-center>
                    <p class="input-label">{{ $t("form.shift") }}</p>
                    <v-select
                      dense
                      outlined
                      multiple
                      :items="shifts"
                      item-text="label"
                      item-value="id"
                      v-model="shift_id"
                      :rules="[RULES.REQUIRED]"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    pt-4
                    pa-lg-2
                    pt-lg-4
                    align-self-center
                    v-if="shift_id.length > 0"
                  >
                    <p class="input-label">{{ $t("form.shift_timing") }}</p>
                    <template v-for="(item, index) in shifts">
                      <v-layout
                        wrap
                        justify-start
                        :key="'shift-' + index"
                        v-if="shift_id.includes(item.id)"
                      >
                        <v-flex xs2 px-2 align-self-center>
                          {{ item.label }}
                        </v-flex>
                        <v-flex xs5 px-2>
                          <p class="input-label">{{ $t("form.start_time") }}</p>
                          <v-text-field
                            placeholder="eg. 15:30"
                            dense
                            outlined
                            v-model="item.start_time"
                            :rules="[RULES.REQUIRED, RULES.WTIME]"
                            v-mask="'##:##'"
                          />
                        </v-flex>
                        <v-flex xs5 px-2>
                          <p class="input-label">{{ $t("form.end_time") }}</p>
                          <v-text-field
                            placeholder="eg. 23:30"
                            dense
                            outlined
                            v-model="item.end_time"
                            :rules="[RULES.REQUIRED, RULES.WTIME]"
                            v-mask="'##:##'"
                          />
                        </v-flex>
                      </v-layout>
                    </template>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap justify-start>
                      <v-flex
                        xs12
                        sm9
                        lg9
                        xl6
                        pt-4
                        pa-sm-2
                        pt-lg-4
                        align-self-center
                      >
                        <AppButton
                          @submit="availableEmployees"
                          custom-class="pa-5"
                          block
                        >
                          <template #content>
                            {{
                              $t("pages.services.show_available_employee_count")
                            }}
                          </template>
                        </AppButton>
                      </v-flex>
                      <v-flex
                        xs12
                        sm3
                        lg3
                        xl6
                        pt-4
                        pa-sm-2
                        pt-lg-4
                        align-self-center
                      >
                        <v-text-field
                          v-model="getServiceEmployeeCount"
                          dense
                          disabled
                          readonly
                          outlined
                          hide-details
                          style="background-color: #ececec"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>
    </v-flex>
    <v-flex xs12>
      <v-card tile height="100%">
        <v-layout wrap justify-start fill-height>
          <v-flex xs12 px-8 text-right align-self-center>
            <span class="px-4">
              <AppNavButton
                :route="{ name: 'services' }"
                color="primary"
                custom-class="pa-5"
                outlined
              >
                <template #content>
                  <span style="color: #3278ff">
                    {{ $t("buttons.cancel") }}
                  </span>
                </template>
              </AppNavButton>
            </span>

            <span class="px-4">
              <AppButton @submit="manageForm" custom-class="pa-5">
                <template #content>
                  {{ $t(`pages.services.${getButtonLabel}`) }}
                </template>
              </AppButton>
            </span>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <SuccessConfirmation
      v-model="showSuccess"
      :title="successTitle"
      :type="successType"
      :item="slug"
      showDuplicate
      @submit="
        () => {
          resetForm();
          $router.go(-1);
        }
      "
      @cancel="
        () => {
          resetForm();
          toggleSuccess();
        }
      "
      @duplicate="toggleSuccess()"
    >
      <template #content>
        <span class="subtitle-2">
          {{ $t("pages.services.available_employee_count") }}
        </span>
        <br />
        <span class="title-2">
          {{ getServiceEmployeeCount }}
        </span>
      </template>
    </SuccessConfirmation>
  </v-layout>
</template>

<script>
import { RULES } from "@/libs/helper/components/rules";
import { LANGUAGES } from "@/constants/languages";
import { GENDERS } from "@/constants/genders";
import { mapActions, mapGetters } from "vuex";
import AppDatePicker from "@/components/AppDatePicker.vue";

export default {
  name: "AddService",
  components: {
    AppDatePicker,
  },
  data: () => ({
    RULES,
    GENDERS,
    slug: null,
    showSuccess: false,
    case_no: null,
    name: null,
    start_date: null,
    end_date: null,
    lettering: null,
    shift_id: [],
    qualification_id: null,
    department_id: null,
    language_id: null,
    gender_id: null,
    date_range: null,
    ward_id: null,
  }),
  computed: {
    ...mapGetters({
      qualifications: "app/getQualifications",
      departments: "app/getDepartments",
      wards: "hospital/getWards",
      getEmployee: "hospital/getEmployee",
      serviceEmployeeCount: "hospital/getServiceEmployeeCount",
    }),
    getServiceEmployeeCount: {
      get() {
        return this.serviceEmployeeCount;
      },
      set(val) {
        this.$store.commit("hospital/setServiceEmployeeCount", val);
      },
    },
    languages() {
      return LANGUAGES.map((itm) => {
        return {
          ...itm,
          label: this.$t(itm.label),
        };
      });
    },
    shifts() {
      return [
        { label: "F", id: 1, start_time: null, end_time: null },
        { label: "M", id: 2, start_time: null, end_time: null },
        { label: "S", id: 3, start_time: null, end_time: null },
        { label: "N", id: 4, start_time: null, end_time: null },
      ];
    },
    minDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    toMinDate() {
      if (this.start_date) return this.start_date;
      return this.$moment().format("YYYY-MM-DD");
    },
    getShiftTimings() {
      return this.shifts.filter((item) => {
        return item.start_time && item.end_time;
      });
    },
    getButtonLabel() {
      return this.$route.params.id ? "update_service" : "add_service";
    },
    successTitle() {
      return this.$route.params.id
        ? this.$t("messages.service_updated")
        : this.$t("messages.service_added");
    },
    successType() {
      return this.$route.params.id ? "edit" : "service";
    },
  },
  mounted() {
    this.$refs.form.reset();
    const breadCrumbs = [
      {
        name: "services",
        text: "pages.services.title",
      },
      {
        name: this.$route.name,
        text: "pages.services.add_service",
      },
    ];
    this.$store.commit("app/setBreadCrumbs", breadCrumbs);

    const ward_payload = {
      per_page: 100,
      page: 1,
    };
    this.getWards(ward_payload);
  },
  created() {
    if (this.$route.query.emp)
      this.getEmployeeDetails(this.$route.query.emp).then(() => {
        this.department_id = this.getEmployee?.departments.map(
          (itm) => itm.department_id
        );
        this.qualification_id = this.getEmployee?.qualifications.map(
          (itm) => itm.qualification_id
        );
        this.language_id = this.getEmployee?.languages.map(
          (itm) => itm.language_id
        );
        this.gender_id = [this.getEmployee?.gender_id];
      });
  },
  methods: {
    ...mapActions({
      getWards: "hospital/getWards",
      getEmployeeDetails: "hospital/getEmployee",
      getServiceEmployeeCountAction: "hospital/serviceEmployeeCount",
    }),
    resetForm() {
      this.$refs.form.reset();
    },
    availableEmployees() {
      if (!this.$refs.form.validate()) return;
      const data = {
        gender_id: this.gender_id,
        qualification_id: this.qualification_id,
        department_id: this.department_id,
        language_id: this.language_id,
        start_date: this.date_range[0],
        end_date: this.date_range[1] ?? this.date_range[0],
        ward_id: this.ward_id,
        shift_id: this.shift_id,
      };
      this.getServiceEmployeeCountAction(data);
    },
    toggleSuccess() {
      this.showSuccess = !this.showSuccess;
    },
    manageForm() {
      if (this.$route.params.id) this.editService();
      else this.addService();
    },
    editService() {
      console.log("edit");
    },
    addService() {
      if (!this.$refs.form.validate()) return;
      const data = {
        case_no: this.case_no,
        name: this.name,
        start_date: this.date_range[0],
        end_date: this.date_range[1] ?? this.date_range[0],
        lettering: this.lettering,
        shift_id: this.shift_id,
        qualification_id: this.qualification_id,
        department_id: this.department_id,
        language_id: this.language_id,
        gender_id: this.gender_id,
        shift_time: this.getShiftTimings,
        ward_id: this.ward_id,
        employee_id: this.$route.query.emp ?? null,
      };
      this.$store
        .dispatch("hospital/addService", data)
        .then((res) => {
          this.$store.commit(
            "hospital/setServiceEmployeeCount",
            res.notification_count
          );
          this.toggleSuccess();
        })
        .catch((err) => {
          console.log(err);
          this.message = err.response.data.message;
          this.snackbarColor = "error";
          this.showSnackbar = true;
        });
    },
  },
};
</script>
<style>
.mycontainer {
  max-height: 80vh;
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
}
</style>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}

.v-text-field--outlined::v-deep input::placeholder,
.v-text-field--outlined::v-deep textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}

.v-input ::v-deep input,
.v-input ::v-deep textarea,
.v-input ::v-deep .v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #081734;
}
</style>
